import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, lastValueFrom, take } from 'rxjs';

export const localeLanguages: Map<string, string> = new Map([
  ['en', 'en-US'],
  ['vi', 'vi-VN'],
]);

@Injectable({
  providedIn: 'root',
})
export class SoctripTranslationService {
  private languageSubject = new BehaviorSubject<any>({});
  language$: Observable<string> = this.languageSubject.asObservable();
  private readonly defaultLocale = 'en-US';
  private lang: string = 'en';

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang.toLowerCase();
      this.languageSubject.next(event.lang);
    });
  }

  getLocale(): string {
    return localeLanguages.get(this.lang) || this.defaultLocale;
  }

  setLanguage(lang: string) {
    localStorage.setItem('lang', lang.toLocaleLowerCase());
    this.translateService.use(lang.toLocaleLowerCase());
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }

  getTranslationWithParam(key: string, param?: Object) {
    let _result: string = '';
    this.translateService
      .get(key, param)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          _result = data;
        },
        complete: () => {
          return _result;
        },
      });
    return _result;
  }

  async getTranslationAsync(key: string, interpolateParams?: Object): Promise<string> {
    try {
      const translation$ = this.translateService.get(key, interpolateParams).pipe(take(1));
      const translation = await lastValueFrom(translation$);
      return translation ?? '';
    } catch (error) {
      return '';
    }
  }

  getCurrentLang() {
    return this.translateService.currentLang;
  }
}
