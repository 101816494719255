<div
  *ngIf="mediaId && isShowPopup && isShowCTA && isLogin === false"
  class="fixed bottom-[75px] left-0 right-0 z-[99999] mx-[8px] flex overflow-hidden rounded-xl hover:cursor-pointer min-[540px]:mx-[30%]"
  (click)="handleClickSignInOrSignUp()"
>
  <img class="h-full w-full object-cover" [src]="mediaId | ImagePath" alt="popup-cta" />

  <button
    class="absolute right-1 top-1 flex h-9 !w-9 items-center justify-center rounded-full bg-palette-gray-100 p-2 text-palette-gray-700 shadow hover:!text-[#f26a3d]"
    (click)="handleClickClose()"
  >
    <i class="sctr-icon-x-close text-xl"></i>
  </button>
</div>
