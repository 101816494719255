<div class="toast-booking">
  <p-toast position="top-right" [key]="'save'">
    <ng-template let-message pTemplate="message">
      <div class="flex flex-col" style="flex: 1">
        <div class="flex gap-3">
          <i
            class="font-semibold"
            [ngClass]="[
              message.severity === 'success' ? 'sctr-icon-check-circle' : 'sctr-icon-alert-circle'
            ]"
            style="font-size: 20px"
          ></i>
          <p class="pb-2 text-md font-medium text-black">
            {{ message.detail }}
          </p>
        </div>
        <div class="pl-[20px]">
          <button
            class="px-[12px] py-[4px] hover:rounded-lg hover:border-solid hover:bg-[#EFF8FF]"
            (click)="openDialog()"
          >
            <p class="text-primary-600 text-md font-semibold">
              {{ 'toast_save_car.change' | translate }}
            </p>
          </button>
        </div>
      </div>
    </ng-template>
  </p-toast>
</div>

<div
  *ngIf="!mobileView; else mobileTemp"
  class="relative rounded-lg border border-[#EAECF0] bg-white bg-opacity-5 shadow-sm max-md:!mb-2 max-md:!p-2 md:!mb-3 md:flex md:!p-4"
>
  <div
    class="relative max-md:!h-[148px] max-md:!w-full md:!h-[186px] md:w-1/3 md:min-w-[33.33%] md:max-w-[33.33%]"
  >
    <a
      [routerLink]="[detailLink()]"
      [queryParams]="detailParams(item.id)"
      [replaceUrl]="true"
      target="_self"
    >
      <img
        class="!h-full min-w-full !max-w-full cursor-pointer rounded-lg border !object-cover"
        (click)="viewDetail()"
        [src]="getPropertyImageId(item)"
      />
    </a>
    <span
      *ngIf="promotion"
      class="bg-gradient-orange absolute !bottom-2 !left-2 rounded-bl-md rounded-tr-md !px-1 !py-0.5 text-center !text-sm font-semibold text-white"
      >-{{ promotion }}%</span
    >
    <div
      (click)="handleBookmark($event, item.id)"
      class="absolute right-2 top-2 cursor-pointer bg-[#10182866] max-md:!h-7 max-md:!w-7 max-md:rounded-md max-md:!p-1.5 md:!h-9 md:!w-9 md:rounded-lg md:!p-2"
    >
      <span *ngIf="isBookmark; then favourite; else unfavourite"></span>
      <ng-template #favourite>
        <img class="!h-5 !w-5" src="assets/images/common-icon/bookmark-close.svg" alt="" />
      </ng-template>
      <ng-template #unfavourite>
        <img class="!h-5 !w-5" src="assets/images/common-icon/bookmark-open.svg" alt="" />
      </ng-template>
    </div>
  </div>

  <div
    class="flex flex-col max-md:!mt-2 md:!ml-4 md:!w-2/3 md:min-w-[calc(66.66%-16px)] md:max-w-[calc(66.66%-16px)]"
  >
    <div class="flex flex-row justify-between">
      <div class="max-md:!w-[80%]">
        <div>
          <a
            [routerLink]="[detailLink()]"
            [queryParams]="detailParams(item.id)"
            [replaceUrl]="true"
            target="_self"
            #hotelName
            class="line-clamp-1 truncate font-inter !font-semibold text-palette-gray-900 max-md:!text-base md:!text-xl"
            (click)="viewDetail()"
          >
            {{ item?.name }}
          </a>
          <span class="line-clamp-1 text-md text-gray-600">
            {{ item?.owner_info?.agency_name }}
          </span>
          <span class="mt-1 line-clamp-2 text-md text-branding-primary-600">
            {{ routeName }}
          </span>
        </div>
      </div>

      <div>
        <div *ngIf="item?.sum_review" class="flex w-fit items-center justify-end">
          <div class="flex items-center gap-2">
            <div class="flex flex-col items-end">
              <span class="w-max text-md font-medium text-palette-gray-900">{{
                getPointRange(item.point)
              }}</span>
              <p class="whitespace-nowrap font-inter text-palette-gray-500 md:!text-sm">
                {{ 'pluralization.total_reviews' | translate : { count: item.sum_review } }}
              </p>
            </div>
            <span
              class="flex min-w-[32px] items-center justify-center rounded-md border border-palette-blue-700 px-2 py-1 font-inter !font-medium text-palette-blue-700 md:!text-base"
            >
              {{ item.point }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-grow flex-col justify-end gap-3">
      <div class="relative flex items-end justify-between">
        <div></div>
        <div class="bottom-0 right-0 col-span-1">
          <div class="max-md:absolute max-md:bottom-0 max-md:right-0 md:!ml-4">
            <div class="flex items-end justify-end">
              <div class="flex justify-end max-md:relative md:!h-full md:items-end">
                <div
                  class="w-max-md:!hidden font-inter !font-medium !text-palette-gray-400 line-through md:!ml-0.5 md:flex md:items-center md:!text-base md:!leading-tight"
                >
                  <app-price-format
                    [keepValue]="userCurrency === item.currency"
                    *ngIf="item.original_price > item.price"
                    [price]="
                      userCurrency === item?.currency
                        ? item.original_price_canal
                        : item.original_price
                    "
                  ></app-price-format>
                </div>
              </div>
              <div class="flex justify-end max-md:relative md:!h-full md:items-end">
                <div
                  class="!ml-0.5 flex items-center font-inter !font-bold !text-palette-orange-dark-500 max-md:!text-[24px] max-md:!leading-[30px] md:!ml-1.5 md:!text-[24px] md:!leading-none"
                >
                  <app-price-format
                    [keepValue]="userCurrency === item.currency"
                    [price]="userCurrency === item?.currency ? item?.price_canal : item?.price"
                  ></app-price-format>
                  <span class="text-base font-medium !text-palette-gray-400"
                    >/{{ 'SEARCH_RESULT.car_list.car' | translate }}</span
                  >
                </div>
              </div>
            </div>
            <div class="flex justify-end max-md:relative md:!h-full md:items-end">
              <span
                class="text-soctrip-small ml-1 w-max overflow-hidden text-ellipsis text-[14px] font-normal text-palette-gray-500"
              >
                {{ 'SEARCH_RESULT.car_list.taxes_charges' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2 max-md:!hidden md:flex md:items-end md:justify-between">
        <div class="flex flex-grow flex-wrap items-center gap-4">
          <div class="flex items-center gap-1">
            <i class="sctr-icon-users-01 text-[#667085]"></i>
            <span class="text-md text-[#667085]"
              >{{ item?.max_customers ?? 0 }}
              {{ 'SEARCH_RESULT.car_list.passengers' | translate }}</span
            >
          </div>
          <div class="flex items-center gap-1">
            <i class="sctr-icon-luggage-01 text-[#667085]"></i>
            <span class="text-md text-[#667085]"
              >{{ item?.max_bags ?? 0 }} {{ 'SEARCH_RESULT.car_list.baggages' | translate }}</span
            >
          </div>
        </div>
        <div class="flex gap-2">
          <!-- <a
            [routerLink]="[detailLink(item.description, item)]"
            [queryParams]="detailParams(item.id)"
            [replaceUrl]="true"
            target="_self"
            class="cursor-pointer whitespace-nowrap rounded-lg border border-branding-primary-600 bg-white px-4 py-2 font-inter !font-semibold text-branding-primary-600 hover:opacity-90 md:!text-base"
            (click)="viewDetail(item.id, item.description, item)"
          >
            Choose
          </a> -->
          <a
            [routerLink]="[detailLink()]"
            [queryParams]="detailParams(item.id)"
            [queryParamsHandling]="'merge'"
            [replaceUrl]="true"
            target="_self"
            class="cursor-pointer whitespace-nowrap rounded-lg border border-branding-primary-600 bg-branding-primary-600 px-4 py-2 font-inter !font-semibold text-palette-base-white hover:opacity-90 md:!text-base"
            (click)="viewDetail()"
          >
            {{ 'SEARCH_RESULT.car_list.view_detail' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #mobileTemp>
  <div
    class="mb-[8px] flex flex-col gap-2 rounded-lg border border-[#EAECF0] bg-white bg-opacity-5 py-[0.75rem] shadow-sm"
    (click)="viewDetail()"
  >
    <div class="t-mob-card-title flex items-center justify-between gap-2 px-[0.75rem]">
      <div class="w-full">
        <a
          [routerLink]="[detailLink()]"
          [queryParams]="detailParams(item.id)"
          [replaceUrl]="true"
          target="_self"
          #hotelName
          class="truncate font-inter text-[14px]/5 !font-semibold text-palette-gray-900"
          (click)="viewDetail()"
        >
          {{ item?.name }}
        </a>
        <span class="line-clamp-1 text-[12px]/4 text-system-gray-500">
          {{ item?.owner_info?.agency_name }}
        </span>
        <div
          class="tooltip-hotel-name z-100 absolute inline-block whitespace-nowrap rounded-lg bg-white !px-3 py-2 text-center text-xs font-semibold text-palette-gray-700 transition-opacity duration-200 ease-in"
        >
          {{ item.vehicle_info?.car_model_name }}
        </div>
      </div>
      <button
        class="t-bookmark-btn flex items-center rounded-lg border border-system-gray-300 bg-white p-1.5"
        (click)="handleBookmark($event, item.id)"
      >
        <i
          [ngClass]="
            isBookmark
              ? 'sctr-icon-solid-bookmark text-[#FDB022]'
              : 'sctr-icon-bookmark text-system-gray-500'
          "
          class="text-base/4"
        ></i>
      </button>
    </div>

    <div class="t-mob-card-body flex gap-2 px-[0.75rem]">
      <div class="relative flex aspect-[3/2] w-[132px]">
        <div
          class="absolute left-1 top-1 rounded-[4px] bg-[#10182866] px-2 py-1"
          *ngIf="item?.point"
        >
          <p class="font-inter text-sm/4 !font-medium text-white">
            {{ item.point }}
          </p>
        </div>
        <a
          [routerLink]="[detailLink()]"
          [queryParams]="detailParams(item.id)"
          [replaceUrl]="true"
          target="_self"
        >
          <img
            class="aspect-[3/2] w-[132px] min-w-[132px] cursor-pointer rounded-md border !object-cover"
            (click)="viewDetail()"
            [src]="getPropertyImageId(item)"
          />
        </a>
        <span
          *ngIf="promotion"
          class="bg-gradient-orange absolute !bottom-2 !left-2 rounded-bl-md rounded-tr-md !px-1 !py-0.5 text-center !text-sm font-semibold text-white"
          >-{{ promotion }}%</span
        >
      </div>

      <div class="flex flex-col flex-wrap gap-2">
        <div class="flex items-center gap-1">
          <i class="sctr-icon-users-01 text-[#667085]"></i>
          <span class="text-md text-[#667085]"
            >{{ item?.max_customers ?? 0 }}
            {{ 'SEARCH_RESULT.car_list.passengers' | translate }}</span
          >
        </div>
        <div class="flex items-center gap-1">
          <i class="sctr-icon-luggage-01 text-[#667085]"></i>
          <span class="text-md text-[#667085]"
            >{{ item?.max_bags ?? 0 }} {{ 'SEARCH_RESULT.car_list.baggages' | translate }}</span
          >
        </div>
      </div>
    </div>
    <div class="t-mob-card-footer border-t border-t-system-gray-200 px-[0.75rem] pt-[0.5rem]">
      <div class="flex items-center justify-end">
        <div class="flex justify-end">
          <div class="font-inter !font-medium !text-palette-gray-400 line-through">
            <app-price-format
              [keepValue]="userCurrency === item.currency"
              *ngIf="item.original_price > item.price"
              [price]="
                userCurrency === item?.currency ? item.original_price_canal : item.original_price
              "
              class="[&_div]:text-md"
            ></app-price-format>
          </div>
        </div>
        <div class="flex justify-end">
          <div
            class="!ml-0.5 flex items-center font-inter !font-bold !text-palette-orange-dark-500"
          >
            <app-price-format
              [keepValue]="userCurrency === item.currency"
              [price]="userCurrency === item?.currency ? item?.price_canal : item?.price"
              class="[&_div]:text-xl"
            ></app-price-format
            ><span class="text-sm font-medium !text-palette-gray-400">
              {{ ' /' }}{{ 'SEARCH_RESULT.car_list.car' | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-toast></app-toast>
<app-dialog-save-list-car
  [visible]="visible"
  [detailId]="item.id"
  (disable)="closeDialog()"
></app-dialog-save-list-car>
