<div class="booking-menu relative flex w-full flex-col gap-2 min-[480px]:flex-row">
  <!-- <div [@slide]="state" class="absolute -top-[37px] overflow-hidden">
    <img class="!w-[5.75rem] !h-[2.25rem]" src="assets/images/home/menu/noel.png" alt="Image" />
  </div> -->
  <div
    class="service flex h-10 w-full items-center justify-between gap-[9px] rounded-lg bg-white p-1 lg:w-fit"
  >
    <ng-container *ngFor="let item of bookingTypeList">
      <button
        (click)="handleClickNav(item)"
        class="h-[32px] rounded-md px-2 py-[6px] transition-all hover:bg-palette-gray-100 sm:px-3"
        [ngClass]="
          bookingType === item.short_name ? 'bg-palette-pink-100 px-1 text-palette-pink-600' : ''
        "
      >
        <common-icon-text
          class="flex h-5 items-center justify-center"
          [icon]="item.icon"
          [iconStyle]="item.text_color + ' text-base flex justify-center items-end'"
          [text]="item.booking_type_name"
          [textStyle]="
            'ml-1 lg:ml-2 whitespace-nowrap text-[9px] sm:text-[14px] font-semibold sm:block'
          "
        ></common-icon-text>
      </button>
    </ng-container>
  </div>
  <button
    class="min-w-[172px] items-center justify-center rounded-lg bg-white px-4 py-2 text-xs font-semibold leading-5 text-palette-blue-600 transition-colors hover:!bg-palette-blue-100 hover:text-palette-blue-700 md:text-sm"
    (click)="showDialog()"
  >
    {{ 'BOOKING_HOME.SEARCH_BOOKING_ID' | soctripTranslate | async }}
  </button>
</div>

<div class="search-booking-id-modal-section h-0">
  <p-dialog
    header="{{ 'MY_BOOKING.SEARCH_YOUR_BOOKING' | soctripTranslate | async }}"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '650px', height: 'auto' }"
    [draggable]="false"
    [resizable]="false"
    (onHide)="onCloseModal()"
    appendTo="body"
    styleClass="t-search-booking-modal"
  >
    <div class="flex flex-col">
      <!-- <div class="!mb-7 flex w-full items-center justify-evenly">
        <div *ngFor="let item of modalBookingTypeList">
          <div (click)="handleNavBookingType(item.short_name)">
            <common-icon-text
              class="flex h-full cursor-pointer items-center gap-2 rounded-[40px] !px-[20px] !py-[8px]"
              [ngClass]="
                modalBookingType === item.short_name
                  ? item.background_color
                  : ''
              "
              [icon]="item.icon"
              [iconStyle]="
                'text-xl font-medium ' +
                (modalBookingType === item.short_name ? item.text_color : '')
              "
              [text]="item.booking_type_name"
              [textStyle]="
                'font-inter text-lg whitespace-nowrap font-semibold ' +
                (modalBookingType === item.short_name
                  ? '' + item.text_color
                  : ' text-palette-gray-500')
              "
            ></common-icon-text>
          </div>
        </div>
      </div> -->

      <div class="tel-input mb-3 flex h-[56px] w-[100%] gap-3 !px-5 !py-[14px]">
        <input
          class="h-[100%] w-[100%] font-inter text-lg font-normal uppercase text-palette-gray-900 outline-none placeholder:normal-case"
          [(ngModel)]="searchBookingId"
          (ngModelChange)="onSearchBookingIdInputChange($event)"
          type="text"
          placeholder="{{ 'MY_BOOKING.PLACEHOLDER.ENTER_BOOKING_ID' | soctripTranslate | async }}"
        />
      </div>

      <div class="tel-input mb-2 flex h-[56px] w-[100%] items-center gap-3 !px-5 !py-[14px]">
        <input
          class="h-[100%] w-[95%] font-inter text-lg font-normal text-palette-gray-900 outline-none"
          #searchInput
          [(ngModel)]="PINcode"
          (ngModelChange)="onSearchBookingIdInputChange($event)"
          type="{{ this.isShowPinCode ? 'text' : 'password' }}"
          placeholder="{{ 'MY_BOOKING.PLACEHOLDER.PIN_CODE' | soctripTranslate | async }}"
        />
        <i
          (click)="togglePassword()"
          class="{{
            isShowPinCode ? 'sctr-icon-eye' : 'sctr-icon-eye-off'
          }} w-[24px] h-[24px] text-xl cursor-pointer text-palette-gray-700"
        ></i>
      </div>

      <div
        *ngIf="responseDataSearch.length == 0 && statusResponse == 200"
        class="mt-3 flex flex-col items-center justify-center"
      >
        <div
          class="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-palette-gray-50"
        >
          <div
            class="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-palette-gray-100"
          >
            <i class="sctr-icon-file-x-02 text-xl text-palette-gray-700"></i>
          </div>
        </div>
        <p class="mb-1 mt-2 font-inter text-lg font-semibold text-palette-gray-900">
          {{ 'MY_BOOKING.NO_RESULTS_FOUND' | soctripTranslate | async }}
        </p>
        <p class="font-inter text-base font-normal text-palette-gray-600">
          {{ 'MY_BOOKING.NO_RESULTS_FOUND_DESCRIPTION' | soctripTranslate | async }}
        </p>
        <p class="font-inter text-base font-normal text-palette-gray-600">
          {{ 'MY_BOOKING.PLEASE_TRY_ANOTHER_ID' | soctripTranslate | async }}
        </p>
      </div>

      <div *ngIf="responseDataSearch.length !== 0 && statusResponse == 200">
        <div *ngFor="let item of responseDataSearch" class="mt-3 cursor-pointer">
          <div
            (click)="handleClickBookingSearchHotel(item)"
            class="flex w-[100%] gap-3 rounded-md bg-palette-gray-50 p-2"
          >
            <img
              *ngIf="item.hotel_image_overview_url"
              class="rounded- !h-[80px] max-w-[80px] object-cover"
              (error)="replaceWithDefaultImage($event)"
              src="{{ baseImageId }}/{{ item.hotel_image_overview_url }}.webp"
            />
            <img
              *ngIf="!item.hotel_image_overview_url"
              class="!h-[80px] max-w-[80px] rounded-md object-cover"
              (error)="replaceWithDefaultImage($event)"
              src="{{ item.hotel_image_overview_url }}"
            />
            <div class="flex w-[100%] flex-col gap-2">
              <common-icon-text
                class="flex items-center gap-2"
                [icon]="bookingType === 'car' ? 'sctr-icon-car-01' : 'sctr-icon-building-07'"
                [iconStyle]="'text-xl text-palette-gray-700'"
                [text]="item.hotel_name"
                [textStyle]="'text-xl font-semibold text-palette-gray-700'"
              ></common-icon-text>
              <div class="flex w-[100%] justify-between">
                <div class="flex flex-col">
                  <p class="font-inter text-sm font-normal text-palette-gray-500">
                    {{ 'PAYMENT.PAYMENT_RESULT.BOOKING_ID' | soctripTranslate | async }}
                  </p>
                  <p class="font-inter text-base font-medium text-branding-primary-700">
                    {{ item.reservation_code }}
                  </p>
                </div>
                <div class="flex flex-col">
                  <p class="font-inter text-sm font-normal text-palette-gray-500">
                    {{ 'STAY_DATES.START_DATE' | soctripTranslate | async }}
                  </p>
                  <p class="font-inter text-base font-medium text-palette-gray-900">
                    {{ item.check_in }}
                  </p>
                </div>
                <div class="flex flex-col">
                  <p class="font-inter text-sm font-normal text-palette-gray-500">
                    {{ 'STAY_DATES.END_DATE' | soctripTranslate | async }}
                  </p>
                  <p class="font-inter text-base font-medium text-palette-gray-900">
                    {{ item.check_out }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        (click)="handleClickSearch()"
        [disabled]="disableSearchButton"
        class="w-[100%] h-[52px] {{
          disableSearchButton
            ? 'bg-branding-primary-200'
            : 'bg-branding-primary-600 hover:bg-branding-primary-700'
        }}  rounded-lg px-5 py-3 font-inter text-lg font-semibold text-white flex justify-center items-center mt-5 !mb-7"
      >
        <div class="flex items-center !gap-3">
          <p>{{ 'COMMON.SEARCH' | soctripTranslate | async }}</p>
          <div *ngIf="isLoading" class="!mt-2 flex items-center justify-center space-x-2">
            <div
              class="loader opacity-1 mb-2 mr-2 h-[20px] w-[20px] rounded-[50%] border-r-[2px] border-t-[2px] border-white"
            ></div>
          </div>
        </div>
      </button>
    </div>
  </p-dialog>
</div>
