import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { CtaSigninService } from '@modules/car-booking/services/cta-signin/cta-signin.service';
import { ClientSBannerControllerService, SBannerDTO } from '@soctrip/angular-banner-service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { TimezoneService } from '@shared/services/timezone.service';
@Component({
  selector: 'app-popup-cta',
  templateUrl: './popup-cta.component.html',
  styleUrls: ['./popup-cta.component.scss'],
})
export class PopupCtaComponent implements OnInit {
  isShowPopup: boolean = true;
  isShowCTA: boolean | null = null;
  isLogin: boolean | null = null;
  ctaSubscription: Subscription;
  loginSubscription: Subscription;
  bannerData: SBannerDTO;
  mediaId: string;
  countryCode: string;
  currentTimeZone: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private CtaSigninService: CtaSigninService,
    private clientSBannerService: ClientSBannerControllerService,
    private timezoneService: TimezoneService
  ) {}
  ngOnInit(): void {
    this.ctaSubscription = this.CtaSigninService.isShowCTA$.subscribe((isShowCTA) => {
      this.isShowCTA = isShowCTA;
    });
    this.loginSubscription = this.userService.getLoginStatus().subscribe((data) => {
      if (data !== null) {
        this.isLogin = data;
      }
      this.cdr.detectChanges();
    });
    setTimeout(() => {
      if (this.isShowCTA === null) {
        this.isShowCTA = true;
      }
    }, 1000);

    let currentCountryCode = localStorage.getItem('currentCountryCode');
    if (currentCountryCode) {
      this.countryCode = currentCountryCode;
    } else {
      this.countryCode = 'US';
    }
    this.currentTimeZone = this.timezoneService.getTimezoneMapping(
      this.countryCode.toLocaleUpperCase()
    );
    this.clientSBannerService
      .getBannerByCode('CAR_SIGNIN_POPUP_BANNERS', this.countryCode, this.currentTimeZone)
      .subscribe((data) => {
        if (data && data.data && data.data.length > 0) {
          this.bannerData = data.data[0];
          this.mediaId = this.bannerData?.medias?.[0]?.id?.toString()!;
        }
      });
  }
  ngOnDestroy(): void {
    this.ctaSubscription.unsubscribe();
    this.loginSubscription.unsubscribe();
  }
  handleClickClose(): void {
    this.isShowPopup = false;
  }
  handleClickSignInOrSignUp(): void {
    // this.router.navigate(['/sign-in']);
    window.location.href = this.bannerData?.url
      ? this.bannerData?.url
      : ` ${environment.SOCIAL_APP_URL}/login?r=${window.location.origin}`;
  }
}
