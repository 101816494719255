<div class="footer-section mt-5 block min-h-[420px] bg-white py-[30px]" *ngIf="isShowFooter">
  <div class="mx-[2%] flex justify-center md:mx-[5%] lg:mx-[8%]">
    <div
      class="flex w-full max-w-[75rem] flex-row justify-between gap-2 border-b-4 border-dashed pb-5 pt-5 md:gap-6"
    >
      <!-- <div class="w-2/12 ml-2">
                <div class="mt-1 min-h-[30px] min-w-[30px] lg:min-w-[124px] !flex justify-start items-center cursor-pointer">
                    <img class="min-w-[34px] max-w-[34px] lg:hidden"
                        src="assets/images/logo/blue-logo-soctrip.svg" alt="">
                    <img class="min-h-[34px] max-h-[34px] hidden lg:block"
                        src="assets/images/logo/blue-soctrip-logo.svg" alt="">
                    <img class="min-w-[34px] max-w-[34px] lg:hidden"
                        src="assets/images/logo/blue-logo.svg" alt="">
                    <img class="min-h-[34px] max-h-[34px] hidden lg:block"
                        src="assets/images/logo/blue-logo-text.svg" alt="">
                </div>
            </div> -->
      <!-- <div class="w-3/12 space-y-1">
                <p
                    class="text-palette-gray-900 text-base font-semibold overflow-hidden whitespace-nowrap text-ellipsis mb-3">
                    {{'FOOTER.CONTACT' | translate}}</p>
                <common-icon-text class="flex space-x-1"
                    [icon]="'sctr-icon-phone-call-01'"
                    [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-center'"
                    [text]="aboutUs.contact.phoneNumber"
                    [textStyle]="'text-detail'"></common-icon-text>
                <common-icon-text class="flex space-x-1 mt-3"
                    [icon]="'sctr-icon-mail-01'"
                    [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-center'"
                    [text]="aboutUs.contact.email"
                    [textStyle]="'text-detail overflow-hidden whitespace-nowrap text-ellipsis'"></common-icon-text>
                <common-icon-text class="flex space-x-1 mt-3"
                    [icon]="'sctr-icon-marker-pin-01'"
                    [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-start'"
                    [text]=" aboutUs.contact.address"
                    [textStyle]="'text-detail line-clamp-4'"></common-icon-text>

                <div class="flex space-x-2 items-center mt-3 mr-1">
                    <div class="cursor-pointer" (click)="handleClickPlatform('facebook')">
                        <img class="w-[20px] h-[20px]"
                            src="{{isHoveredFacebook ? 'assets/images/home/icon/active-facebook-icon.svg' : 'assets/images/home/icon/gray-facebook-icon.svg'}}"
                            alt="" (mouseenter)="onMouseEnterIcon('facebook')"
                            (mouseleave)="onMouseLeaveIcon('facebook')">
                    </div>
                    <div class="cursor-pointer" (click)="handleClickPlatform('youtube')">
                        <img class="w-[20px] h-[20px]"
                            src="{{isHoveredYoutube ? 'assets/images/home/icon/active-youtube-icon.svg' : 'assets/images/home/icon/gray-youtube-icon.svg'}}"
                            alt="" (mouseenter)="onMouseEnterIcon('youtube')"
                            (mouseleave)="onMouseLeaveIcon('youtube')">
                    </div>
                    <div class="cursor-pointer flex items-center space-x-2" (click)="handleClickChangeLanguage()">
                        <img class="h-[16px] w-[16px]" src="assets/images/home/icon/gray-earth-icon.svg" alt="">
                        <p
                        class="text-detail overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer w-[25px] hover:text-blue-500">
                        {{selectedLanguage.country_code}}</p>
                    </div>

                </div>
            </div> -->
      <div class="md:w-[282px]">
        <p class="mb-3 text-base font-semibold text-palette-gray-900">
          {{ 'FOOTER.ABOUT' | translate }}
        </p>
        <div *ngFor="let item of aboutUs.info; let i = index" class="flex">
          <a
            [href]="item.link"
            class="text-detail !text-md hover:text-blue-500 cursor-pointer {{
              i == 0 ? '' : 'mt-2'
            }}"
          >
            {{ item.name | translate }}
          </a>
        </div>
      </div>
      <div class="space-y-1 md:w-[282px]">
        <p class="mb-3 text-base font-semibold text-palette-gray-900">
          {{ 'HEADER.MENU.BOOKING' | translate }}
        </p>
        <div *ngFor="let item of aboutUs.services; let i = index" class="flex">
          <a
            [href]="item.link"
            class="text-detail !text-md hover:text-blue-500 cursor-pointer {{
              i == 0 ? '' : 'mt-[10px]'
            }}"
          >
            {{ item.name | translate }}
          </a>
        </div>
      </div>
      <div class="space-y-1 md:w-[282px]">
        <p class="mb-3 text-base font-semibold text-palette-gray-900">
          {{ 'FOOTER.other_services' | translate }}
        </p>
        <div *ngFor="let item of aboutUs.otherServices; let i = index" class="flex">
          <a
            [href]="item.link"
            class="text-detail !text-md hover:text-blue-500 cursor-pointer {{
              i == 0 ? '' : 'mt-[10px]'
            }}"
          >
            {{ item.name | translate }}
          </a>
        </div>
      </div>
      <div class="md:w-[282px]">
        <p class="mb-3 text-base font-semibold text-palette-gray-900">
          {{ 'FOOTER.SUBSCRIPTION' | translate }}
        </p>
        <p class="text-detail !text-md">
          {{ 'FOOTER.SUBSCRIPTION_DESCRIPTION' | translate }}
        </p>
        <form [formGroup]="subscribeForm" (ngSubmit)="onSubmitSubscribe()">
          <div class="mb-2 mt-3 flex max-[1400px]:flex-col">
            <input
              type="text"
              formControlName="email"
              pInputText
              class="h-[44px] max-[1400px]:w-[100%] mr-2 w-[65%] font-inter font-normal
                            text-base text-palette-gray-500 border
                            {{
                submittedSubscribe &&
                (subscribeForm.get('email')?.hasError('pattern') ||
                  subscribeForm.get('email')?.hasError('invalidEmail')) &&
                subscribeForm.get('email')?.touched
                  ? 'border-red-500'
                  : 'border-[#ced4da]'
              }}
                            {{
                submittedSubscribe &&
                subscribeForm.get('email')?.hasError('required') &&
                subscribeForm.get('email')?.touched
                  ? 'border-red-500'
                  : 'border-[#ced4da]'
              }}"
              #textInput
              [(ngModel)]="emailRegister"
              placeholder="{{ 'FOOTER.PLACEHOLDER.YOUR_EMAIL' | translate }}"
              (input)="onInputChange(textInput.value)"
            />
            <!-- <p-button
              type="submit"
              class="register-btn h-[44px] w-[35%] whitespace-nowrap rounded-lg !font-inter !font-semibold text-white max-[1400px]:mt-2 max-[1400px]:w-[100%]"
              label="{{ 'COMMON.SUBSCRIBE' | translate }}"
            ></p-button> -->
            <button
              type="submit"
              class="register-btn border-primary-600 bg-primary-600 flex h-[48px] items-center rounded-lg border px-3 py-3"
            >
              <i class="sctr-icon-send-01 text-2xl/5 text-white"></i>
            </button>
          </div>
        </form>
        <div>
          <div
            class="py-2 text-sm italic text-red-600"
            *ngIf="
              submittedSubscribe &&
              subscribeForm.get('email')?.hasError('required') &&
              subscribeForm.get('email')?.touched
            "
          >
            {{ 'FOOTER.VALIDATION.EMAIL_REQUIRED' | translate }}
          </div>
          <div
            class="py-2 text-sm italic text-red-600"
            *ngIf="
              submittedSubscribe &&
              (subscribeForm.get('email')?.hasError('pattern') ||
                subscribeForm.get('email')?.hasError('invalidEmail')) &&
              subscribeForm.get('email')?.touched
            "
          >
            {{ 'FOOTER.VALIDATION.EMAIL_INVALID' | translate }}
          </div>
          <div
            class="py-2 text-sm italic text-green-600"
            *ngIf="submittedSubscribe && isRegisterSuccess"
          >
            {{ 'FOOTER.VALIDATION.THANK_YOU' | translate }}
          </div>
        </div>
        <div>
          <div class="flex space-x-4 pt-3">
            <div class="cursor-pointer" (click)="handleLinkSubscription('Google Play')">
              <img src="assets/images/home/affiliate-brand/google-play.png" alt="" />
            </div>
            <div class="cursor-pointer" (click)="handleLinkSubscription('App Store')">
              <img src="assets/images/home/affiliate-brand/app-store.png" alt="" />
            </div>
          </div>
          <div class="flex space-x-4 pt-3">
            <div class="cursor-pointer" (click)="handleLinkSubscription('Registered')">
              <img src="assets/images/home/affiliate-brand/gov-vn-registered.png" alt="" />
            </div>
            <div class="cursor-pointer" (click)="handleLinkSubscription('Announced')">
              <img src="assets/images/home/affiliate-brand/gov-vn.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mx-[2%] flex justify-center md:mx-[5%] md:flex-row lg:mx-[8%]">
    <div class="flex w-full max-w-[75rem] flex-col justify-between gap-2 pt-3 md:flex-row">
      <div
        class="flex w-[282px] flex-col justify-between gap-2 pt-3 md:flex-row"
        *ngFor="let item of contact; let i = index"
      >
        <div class="flex flex-col gap-1 px-1">
          <p class="mb-3 text-base font-semibold text-palette-gray-900">
            {{ item.origin }}
          </p>

          <div class="flex flex-col !text-md">
            <common-icon-text
              class="flex space-x-1"
              [icon]="'sctr-icon-phone-call-01'"
              [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-center'"
              [text]="item.phone"
              [textStyle]="'text-detail !text-md'"
            ></common-icon-text>

            <common-icon-text
              class="mt-3 flex space-x-1"
              [icon]="'sctr-icon-mail-01'"
              [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-center'"
              [text]="item.mail"
              [textStyle]="'text-detail overflow-hidden whitespace-nowrap text-ellipsis !text-md'"
            ></common-icon-text>

            <common-icon-text
              class="mt-3 flex space-x-1"
              [icon]="'sctr-icon-marker-pin-01'"
              [iconStyle]="'text-palette-orange-500 text-lg mt-0.5 mr-1 flex items-start'"
              [text]="item.address"
              [textStyle]="'text-detail line-clamp-4 !text-md'"
            ></common-icon-text>
          </div>
        </div>
      </div>
      <div class="flex w-[282px] justify-center pt-3">
        <p class="text-[14px]">
          {{
            'common.term.copyright' | translate : { year: currentYear, product_name: product_name }
          }}
        </p>
      </div>
    </div>
  </div>
</div>

<div>
  <p-dialog
    header="{{ 'FOOTER.LANGUAGE_SETTING.TITLE' | translate }}"
    [(visible)]="visiblePopupChangeLanguage"
    [modal]="true"
    [style]="{ width: '480px', height: '419px', position: 'absolute' }"
    [draggable]="false"
    (onHide)="onCloseModal()"
    [blockScroll]="true"
  >
    <div class="relative flex h-full flex-col bg-white px-4 pb-0">
      <div class="space-y-3">
        <div>
          <div class="mb-[6px] text-sm font-medium text-palette-gray-700">
            {{ 'FOOTER.LANGUAGE_SETTING.REGION' | translate }}
          </div>
          <p-dropdown
            [options]="countries"
            [(ngModel)]="selectedCountry"
            optionLabel="name"
            styleClass="!h-[44px] w-full"
            (onChange)="onChangeCountry($event)"
          ></p-dropdown>
        </div>
        <div>
          <div class="mb-[6px] text-sm font-medium text-palette-gray-700">
            {{ 'FOOTER.LANGUAGE_SETTING.LANGUAGE' | translate }}
          </div>
          <p-dropdown
            [options]="languages"
            [(ngModel)]="selectedLanguage"
            optionLabel="name"
            styleClass="!h-[44px] w-full"
            (onChange)="onChangeLanguage($event)"
          >
            <ng-template let-language pTemplate="item">
              <div class="align-items-center flex gap-2">
                <img src="{{ language.icon_name }}" style="width: 18px" />
                <div>{{ language.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div>
          <div class="mb-[6px] text-sm font-medium text-palette-gray-700">
            {{ 'FOOTER.LANGUAGE_SETTING.CURRENCY' | translate }}
          </div>
          <p-dropdown
            [options]="currencies"
            [(ngModel)]="selectedCurrency"
            optionLabel="value"
            styleClass="!h-[44px] w-full"
            (onChange)="onChangeCurrency($event)"
          >
            <ng-template let-currency pTemplate="item">
              <div class="align-items-center operatingSystems-item flex">
                <div>{{ currency.code }} {{ currency.symbol }}</div>
              </div>
            </ng-template></p-dropdown
          >
        </div>
      </div>

      <div class="absolute bottom-4 right-4 flex h-[44px] justify-end space-x-2">
        <div
          class="p-button-text flex h-full w-[100px] cursor-pointer justify-center rounded-lg border border-palette-gray-700 px-[18px] py-2.5 text-base font-semibold text-palette-gray-700"
          (click)="onCloseModal()"
        >
          {{ 'COMMON.CANCEL' | translate }}
        </div>
        <div
          (click)="handleClickApply()"
          class="flex h-[44px] w-[100px] cursor-pointer items-center justify-center rounded-lg bg-branding-primary-600 font-inter text-lg font-semibold text-white"
        >
          {{ 'COMMON.APPLY' | translate }}
        </div>
      </div>
    </div>
  </p-dialog>
</div>
